svg.bell {
  cursor:pointer;
}
#Path {
  transform-origin: 50% 50%;
  animation-name: bellring;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 2;
}

@keyframes bellring {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(10deg); }
  20% { transform: rotate(-10deg); }
  30% { transform: rotate(10deg); }
  40% { transform: rotate(-10deg);}
  50% { transform: rotate(0deg);}
}
